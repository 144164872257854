<template>
    <footer class="aa-footer">
        <div class="info p-2">
        <div class="aa-footer-title">
            Instituto de Enseñanza Secundaria
        </div>
        <div class="aa-footer-contacto mt-1">
            <address class="aa-footer-direccion">
                Paseo de la Alameda, 21 <br>
                41530 Morón de la Frontera <br>
                Sevilla
            </address>
            <div class="aa-footer-datos">
                <b>Tel.:</b> +34 955 859 519 <br>
                <b>Fax:</b> +34 955 851 080 <br>
                <b>Email.</b> 41002955.edu@juntadeandalucia.es

            </div>
        </div>
        </div>
        <div class="escudo p-2">
            <img src="../../../assets/img/escudo_blanco.svg" alt="Emblema Fray Bartolomé de las Casas" title="Emblema Fray Bartolomé de las Casas" />
        </div>
    </footer>
</template>


<style lang="sass" scoped>

.aa-footer
    align-items: center
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    width: 100vw
.escudo
    width: 170px
    margin: 0 auto
    @include breakpoint(md)
        margin: 0
</style>