<template>
    <NavBar />
    <div class="aa-container">
        <router-view></router-view>
    </div>
    <Footer />
</template>

<script>

import NavBar from '../components/NavBar.vue'
import Footer from "../components/Footer.vue";

export default {
    name: 'baseLayout',
    components: { NavBar, Footer },
    setup(){

    }

}

</script>

<style>

</style>