<template>
    <header class="aa-menu">
        <div class="aa-menu-logo mt-1">
            <img src="../../../assets/img/back.svg" id="back" class="ms-1" v-if="$route.name !== 'home'" @click="atras" />
            
            <router-link :to="{name:'home'}">
                <img src="../../../assets/img/logo.svg" id="imgLogo" class="ms-1" />
                <img src="../../../assets/UI/emblema.svg" id="imgLogoR" class="ms-1" />
            </router-link>

        </div>
        <div class="aa-menu-acciones me-1">
            <ul class="aa-menu-rapido">
                <li ><router-link :to="{name:'areas'}" class="d-block"> Áreas Educativas </router-link></li>
                <li ><router-link :to="{name:'oferta'}" class="d-block"> Oferta Educativa </router-link></li>
                <li ><router-link :to="{name:'bilinguismo'}" class="d-block"> Bilingüismo </router-link></li>
                <li ><router-link :to="{name:'noticias'}" class="d-block"> Noticias </router-link></li>
                <li ><router-link :to="{name:'anuncios'}" class="d-block"> Anuncios </router-link></li>
                <li ><a href="http://profesorado.iesfraybartolome.es" class="d-block pb-1 pt-1"> Área de profesorado </a></li>
            </ul>
            <div class="aa-menu-menu">
                <img src="../../../assets/img/menu.svg" @click="mostrar" />
            </div>
            
        </div>
    </header>
    
    <div class="aa-menu-box" ref="menuref">
            <div class="aa-menu-box-content">
                <div class="escudo">
                    <img src="../../../assets/img/escudo_blanco.svg" class="mt-2 mx-auto pe-1 escudo-img" />
                </div>
                <ul class="ms-2 text-start">
                    <li @click="ir('areas')"><span class="d-block pb-1 pt-1"> Áreas Educativas </span></li>
                    <li @click="ir('oferta')"><span class="d-block pb-1 pt-1"> Oferta Educativa </span></li>
                    <li @click="ir('administracion')"><span class="d-block pb-1 pt-1"> Administración </span></li>
                    <li @click="ir('bilinguismo')"><span class="d-block pb-1 pt-1"> Bilingüismo </span></li>
                    <li @click="ir('noticias')"><span class="d-block pb-1 pt-1"> Noticias </span></li>
                    <li @click="ir('anuncios')"><span class="d-block pb-1 pt-1"> Tablón de anuncios </span></li>
                    <li><a href="http://profesorado.iesfraybartolome.es" class="d-block pb-1 pt-1"> Área de profesorado </a></li>
                </ul>    

                <div class="cerrar text-start ms-2 mb-2 aa-text-h2 fw-500" @click="cerrar"> 
                    <img src="../../../assets/img/close.svg" /> Cerrar
                </div>
            </div>
        </div>
</template>


<script>
import { onMounted } from 'vue'
import { gsap, Power2, Sine} from 'gsap'


import { useRouter } from 'vue-router'


export default {
    setup(){
            const router = useRouter()

            let tl = gsap.timeline()

            const creaAnima = () => {
                tl
                    .to('.escudo-img', {autoAlpha:1, scale:1, duration: 0.3})
                    .to('.aa-menu-box', {duration: 0.4, autoAlpha:1},0)
                    .from('.aa-menu-box-content ul li',{
                        x: 500,
                        alpha: 0,
                        ease: Sine.easeOut,
                        stagger: {
                            each: 0.1,
                            from: "left",
                            ease: Power2.easeOut
                        }
                    })
                    .from('.cerrar', {
                        duration: 0.5,
                        y: 500,
                        alpha:0
                    },1)
                    .reverse()
            }

            const mostrar = () => {
                tl.play()
                document.body.style.setProperty('overflow', 'hidden');
            }

            const cerrar = () => {
                tl.reverse()
                document.body.style.removeProperty('overflow');

            }


            onMounted(()=>{
                creaAnima()
                let logo = document.getElementById("imgLogo")
                // let logoR = document.getElementById("imgLogoR")

                window.addEventListener("touchmove", (e) => {
                    let posY = e.changedTouches[0].pageY
                    
                    if (logo){
                        if (posY > 600){
                            logo.style.height = "30px";
                            if (document.getElementById("back")){
                                document.getElementById("back").style.height = "20px";
                            }
                        }else{
                            logo.style.height = "75px";
                            if (document.getElementById("back")){
                                document.getElementById("back").style.height = "30px";
                            }
                        }
                    }

                    
                })
                window.addEventListener("scroll", () => {
                    if (document.getElementById("imgLogo")){

                        if (window.scrollY > 170){
                            logo.style.height = "30px";
                            if (document.getElementById("back")){
                                document.getElementById("back").style.height = "20px";
                            }
                        }else{
                            logo.style.height = "75px";
                            if (document.getElementById("back")){
                                document.getElementById("back").style.height = "30px";
                            }
                        }
                    }
                    
                })
            })

            return {
                mostrar,
                cerrar,
                atras: () => router.go(-1),
                ir: (pagina) => {
                    cerrar()
                    router.push({name:pagina})
                }
            }
        }

}
</script>
<style lang="sass" scoped>
.aa-menu
    $root:&
    &-menu
        margin-block-end: 0.4rem    
        
.aa-menu-rapido
    display: none
    @include breakpoint(lg)
        display: block
    li
        display: inline-block
        margin-left: 20px
        margin-right: 20px
        cursor: pointer
        overflow: hidden
        position: relative
        a   
            font-weight: 500
            text-decoration: none
            border-top: 4px solid rgba(255, 255, 255, 0)
            border-bottom: 4px solid rgba(255, 255, 255, 0)
            padding: 0px
            
            transition: all 500ms
            &:hover
                border-bottom: 3px solid #002163
                padding: 6px 0
    
.aa-menu-box
    $root:&
    position: fixed
    top: 0
    left: 0
    z-index: 999
    height: 100vh
    width: 100vw
    background-color: #00163d
    visibility: hidden
    &-content
        display: flex
        flex-direction: column
        row-gap: 20px
        .escudo
            img
                width: 120px
                transform: scale(0)
        ul
            list-style: none
            font-size: 1.3rem
            padding: 10px
            li
                cursor: pointer
                a, span
                    text-decoration: none
                    color: #fff
                    font-weight: 300
        .cerrar
            color: #fff
            bottom: 0px
            position: fixed
            img
                height: 17px
    

#imgLogoR
    display: none
    opacity: 1
    transform: translate(-100px, 0px)
    position: absolute
    top: 5px
    left: 0
    height: 40px
</style>